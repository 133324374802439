import React from "react";

import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedIn from "./layout/ProtectedIn";
import ProtectedOut from "./layout/ProtectedOut";
import LoginScreen from "./screens/login-screen/LoginScreen";
import ConfirmationScreen from "./screens/confirmation-screen/ConfirmationScreen";
import HomeScreen from "./screens/home-screen/HomeScreen";
import IframeScreen from "./screens/iframe-screen/IframeScreen";
import HomeHeader from "./components/home-header/HomeHeader";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route
          path="/login"
          element={
            <ProtectedIn>
              <LoginScreen />
            </ProtectedIn>
          }
        />

        {/* Protected Routes */}
        <Route
          path="/confirmation"
          element={
            <ProtectedIn>
              <ConfirmationScreen />
            </ProtectedIn>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedOut>
              <>
                <HomeHeader />
                <HomeScreen />
              </>
            </ProtectedOut>
          }
        />
        <Route
          path="/application/:appName"
          element={
            <ProtectedOut>
              <>
                <HomeHeader />
                <IframeScreen />
              </>
            </ProtectedOut>
          }
        />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
