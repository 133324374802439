import animationData from "./empty.json";
import animationDataLoading from "./loading.json";

import FourDotJson from "./threedots.json";

import MultiColor from "./multicolor.json";

import Chbayah from "./chnayah.json";

export const defaultOptionsEmpty = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export const defaultOptionsLoading = {
  loop: true,
  autoplay: true,
  animationData: animationDataLoading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const FourDotsLoading = {
  loop: true,
  autoplay: true,
  animationData: FourDotJson,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const MultiColorLoading = {
  loop: true,
  autoplay: true,
  animationData: MultiColor,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const ChbayahAnimation = {
  loop: true,
  autoplay: true,
  animationData: Chbayah,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
