import customEnv from "./envCustom.json";

type envConfType = {
  IS_DEV: boolean;
  SECRET_KEY: string;
  SERVER_URL: string;
};

const SERVER_URL =
  customEnv.SERVER_URL || process.env.REACT_APP_SERVER_LB4_URL || "";

const IS_DEV =
  (customEnv.IS_DEV || process.env.REACT_APP_IS_DEV)?.toUpperCase() === "TRUE"
    ? true
    : false;

const SECRET_KEY =
  customEnv.SECRET_KEY || process.env.REACT_APP_SECRET_KEY || "";

export const envconf: envConfType = {
  SERVER_URL,
  IS_DEV,
  SECRET_KEY,
};

export default envconf;
