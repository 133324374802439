import OMSICON from "../assets/app-icons/oms.png";

import STOREICON from "../assets/app-icons/store.png";

import INVENTORYICON from "../assets/app-icons/inventory.png";

import HRICON from "../assets/app-icons/management.png";

import PURCHASEICON from "../assets/app-icons/achat.png";

import EXPENSEICON from "../assets/app-icons/expense.png";

export const appsData = [
  {
    appname: "OMS",
    url: "https://oms.pizzaie.com/",
    icon: OMSICON,
    color: "#252528",
  },
  {
    appname: "STORE",
    url: "https://store.pizzaie.com/",
    icon: STOREICON,
    color: "#149B7B",
  },
  {
    appname: "INVENTORY",
    url: "https://inventory-client.pizzaie.com/",
    icon: INVENTORYICON,
    color: "#AD9E89",
  },
  {
    appname: "MANAGEMENT",
    url: "https://hr.pizzaie.com/",
    icon: HRICON,
    color: "#505099",
  },
  {
    appname: "ACHAT",
    url: "https://purchase.pizzaie.com/",
    icon: PURCHASEICON,
    color: "#96A572",
  },
  {
    appname: "EXPENSE",
    url: "https://expense.pizzaie.com/",
    icon: EXPENSEICON,
    color: "#1A5F9E",
  },
];
