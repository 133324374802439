import React from "react";
import { Navigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/localstorage";
import isTokenExpired from "../helpers/isTokenExpired";
import { LocalStorageEnum } from "../utils/localstorage/LocalStorageEnum";
import envconf from "../envConfig";

interface ProtectedInProps {
  children: JSX.Element;
}

const ProtectedIn: React.FC<ProtectedInProps> = ({ children }) => {
  if (envconf?.IS_DEV) {
    return children;
  }

  const token = getFromLocalStorage(LocalStorageEnum.TOKEN);

  if (isTokenExpired(token || "") || !token) {
    return children;
  }

  return <Navigate to="/home" replace />;
};

export default ProtectedIn;
