// utils/getAppUrl.ts

import { appsData } from "../utils/appsData";

// Function to get the URL for a given appname
export const getAppColor = (appName: string): string | undefined => {
  const app = appsData.find(
    (app) => app.appname?.toUpperCase() === appName?.toUpperCase()
  );
  return app ? app?.color : ""; // Return URL if found, else undefined
};
