import React, { useState } from "react";
import "./loginscreen.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PhoneInput, {
  isValidPhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import extractPhoneNumber from "../../helpers/extractPhoneNumber";
import verifyAceesByPhoneNumber from "../../services/verify-phoneNumber-access";
import { FourDotsLoading } from "../../assets/lottie-animation/animationOptions";

import LogoPizzaieHub from "../../assets/logo/logo-pizzaiehub.png";
import Lottie from "react-lottie";

function LoginScreen() {
  const [loading, setLoading] = useState(false);
  const [fullPhoneNumber, setFullPhoneNumber] = useState<string>("");
  const [countryCode, setCountryCode] = useState<"TN" | "CA">("TN");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const navigate = useNavigate();

  const handlePhoneNumberChange = (value: any) => {
    if (value && value?.length) {
      setErrorMessage("");

      setFullPhoneNumber(value);
      const isValidPH = isValidPhoneNumber(value, countryCode);
      if (!isValidPH) {
        setErrorMessage("Invalid phone number !");
        return;
      }
    } else {
      setErrorMessage("");
    }
  };

  const handleCountryChange = (country: any) => {
    setCountryCode(country);
  };

  const loginAct = async (e: any) => {
    try {
      e?.preventDefault();
      setLoading(true);
      const countryCallingCode = getCountryCallingCode(countryCode);
      const { phoneNumber } = extractPhoneNumber(
        fullPhoneNumber,
        "+" + countryCallingCode
      );

      const { data } = await verifyAceesByPhoneNumber(phoneNumber);
      if (!data?.success) {
        throw new Error("Access denied : " + data?.message);
      }
      setFullPhoneNumber("");

      toast.success("Access granted !");
      setLoading(false);

      navigate("/confirmation", {
        state: {
          countryCode: "+" + countryCallingCode,
          phoneNumber: phoneNumber,
        },
      });
    } catch (error: any) {
      setLoading(false);

      setFullPhoneNumber("");

      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error varifying access to the app"
      );
    }
  };

  return (
    <div className="background-image-wrapper">
      <div className="form-container">
        <div className="login-logo-wrapper">
          <img src={LogoPizzaieHub} className="logo-login" alt="logo login" />
          <p className="title-logo">PIZZAIE HUB</p>
        </div>
        {loading ? (
          <div className="loading-content">
            {/* @ts-ignore */}
            <Lottie
              options={FourDotsLoading}
              height={"150px"}
              width={"150px"}
              isClickToPauseDisabled
              speed={1.3}
            />
          </div>
        ) : (
          <form onSubmit={loginAct}>
            <div className="input-wrapper">
              <PhoneInput
                defaultCountry={countryCode}
                placeholder="Enter phone number"
                value={fullPhoneNumber}
                onChange={handlePhoneNumberChange}
                onCountryChange={handleCountryChange}
                style={{
                  width: "100%",
                  padding: "10px",
                }}
                className="input-phone"
                countries={["TN", "CA"]}
                addInternationalOption={false}
                labels={{
                  TN: "Tunsia",
                  CA: "Canada",
                }}
                numberInputProps={{
                  required: true,
                }}
              />
            </div>
            <p className="error-message">{errorMessage}</p>
            <button
              className="button"
              type="submit"
              disabled={Boolean(errorMessage?.length)}
            >
              Login
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default LoginScreen;
