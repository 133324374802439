// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #f5e7cc, #febf3f);
  padding: 1.5vh 2vh;
}
.home-header-container .home-header-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.home-header-container .home-header-logo-container .home-header-logo-img {
  height: 6vh;
  width: 6vh;
  object-fit: contain;
}
.Overlay {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/home-header/homeheader.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,uDAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAAI;EACE,WAAA;EACA,UAAA;EACA,mBAAA;AAEN;AAYA;EACE,qBAAA;AAVF","sourcesContent":[".home-header-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background: linear-gradient(to right, #f5e7cc, #febf3f);\n  padding: 1.5vh 2vh;\n\n  .home-header-logo-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    .home-header-logo-img {\n      height: 6vh;\n      width: 6vh;\n      object-fit: contain;\n    }\n    // .home-header-logo-title {\n    //   font-weight: 900;\n    //   color: #4c4c4c;\n    //   white-space: nowrap;\n    //   margin-left: 3vh;\n    // }\n  }\n\n  .home-header-profile-section {\n  }\n}\n\n.Overlay {\n  background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
