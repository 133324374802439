import React, { useEffect, useRef, useState } from "react";
import "./iframescreen.scss";
import { useNavigate, useParams } from "react-router-dom";
import { getAppUrl } from "../../helpers/getAppUrl";
import { SyncOutlined } from "@ant-design/icons";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../utils/localstorage";
import { FourDotsLoading } from "../../assets/lottie-animation/animationOptions";
import Lottie from "react-lottie";

import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { LocalStorageEnum } from "../../utils/localstorage/LocalStorageEnum";
import { toast } from "react-toastify";
import verifyAccess from "../../services/verifyAccess";

function IframeScreen() {
  const { appName } = useParams();

  const appUrl = getAppUrl(appName || "") || "";

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [loadingIframe, setLoadingIframe] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);

  const navigate = useNavigate();

  const verifyAccessToApplication = async () => {
    try {
      const phoneNumber =
        getFromLocalStorage(LocalStorageEnum.PHONENUMBER) || "";
      if (!phoneNumber && phoneNumber?.length) {
        toast.error("Phone number does not exist ! ");
        deleteFromLocalStorage(LocalStorageEnum.PHONENUMBER);
        deleteFromLocalStorage(LocalStorageEnum.TOKEN);
        navigate("/login");
      }
      const { data } = await verifyAccess(phoneNumber, appName || "");

      if (!data?.success) {
        throw new Error("Access denied : " + data?.message);
      }
    } catch (error: any) {
      navigate("/home");
      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error varifying access to the app"
      );
    }
  };

  const sendMessageToIframe = () => {
    setLoadingIframe(true);

    const iframe = iframeRef.current || {};
    const token = getFromLocalStorage(LocalStorageEnum.TOKEN) || "";
    if (iframe && token) {
      const message = {
        action: "SET_LOCAL_STORAGE",
        key: "token",
        value: token,
      };

      (iframe as any).contentWindow.postMessage(message, appUrl);

      setTimeout(() => {
        setLoadingIframe(false);
      }, 1000);
    } else {
      console.error("Token not found");
      setTimeout(() => {
        setLoadingIframe(false);
      }, 1000);
    }
  };

  useEffect(() => {
    verifyAccessToApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ready && sendMessageToIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  return (
    <div className="iframe-container">
      <Tooltip
        id="my-tooltip"
        variant="dark"
        float
        noArrow
        opacity={0.8}
        clickable={false}
        place="bottom-end"
      />
      <button
        className="refresh-button"
        onClick={() => {
          setLoadingIframe(true);
          setReady(false);
          sendMessageToIframe();
        }}
      >
        <SyncOutlined
          spin
          data-tooltip-id={"my-tooltip"}
          data-tooltip-content={"Rafraîchir"}
        />
      </button>
      {loadingIframe && (
        <div className="loader-content">
          {/* @ts-ignore */}
          <Lottie options={FourDotsLoading} height={"150px"} width={"150px"} />
        </div>
      )}
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        ref={iframeRef}
        className="iframe-container"
        src={loadingIframe ? "" : appUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
        loading="lazy"
        onLoad={() => {
          setReady(true);
        }}
        onLoadCapture={() => {}}
      />
    </div>
  );
}

export default IframeScreen;
