import React, { useEffect, useState } from "react";
import "./homescreen.scss";
import { getFromLocalStorage } from "../../utils/localstorage";
import isTokenExpired from "../../helpers/isTokenExpired";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getProfileById from "../../services/getProfileById";
import { getAppIcon } from "../../helpers/getAppIcon";
import { getAppColor } from "../../helpers/getAppColor";
import Lottie from "react-lottie";
import {
  ChbayahAnimation,
  MultiColorLoading,
} from "../../assets/lottie-animation/animationOptions";
import { LocalStorageEnum } from "../../utils/localstorage/LocalStorageEnum";
import { ProfileDataType } from "../../utils/Types/ProfileDataType";

function HomeScreen() {
  const [loading, setLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<ProfileDataType | null>();
  const [hoveredApp, setHoveredApp] = useState<string | null>(null); // Track hovered app
  const navigate = useNavigate();

  const handleNavigate = (appname: string) => {
    if (appname) {
      navigate(`/application/${appname}`);
    }
  };

  const handleGetProfileInfo = async () => {
    try {
      setLoading(true);
      const token = getFromLocalStorage(LocalStorageEnum.TOKEN) || "";

      if (isTokenExpired(token) || !token) {
        toast.error("Session expired, you will be disconnected!");
        return <Navigate to="/login" replace />;
      }

      const decodedToken = JSON.parse(atob(token?.split(".")?.[1])) || {};
      const id = decodedToken?.user?.id || decodedToken.id || "";

      if (!id && !id?.length) {
        toast.error("ID not found, try to reconnect or try again later!");
        setLoading(false);
        return;
      }
      const { data } = await getProfileById(id);

      setProfileData(data?.content?.user);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetProfileInfo();
  }, []);
  return (
    <div className="home-screen">
      {loading ? (
        <div className="loader-container">
          {/* @ts-ignore */}
          <Lottie
            options={MultiColorLoading}
            height={"300px"}
            width={"300px"}
          />
        </div>
      ) : profileData?.Apps?.length ? (
        <div className="apps-container">
          {profileData?.Apps?.map((app: string, index: number) => (
            <div
              key={index}
              className="app-card"
              onClick={() => handleNavigate(app)}
              onMouseEnter={() => setHoveredApp(app)} // Set hovered app
              onMouseLeave={() => setHoveredApp(null)} // Reset hovered app
            >
              <img
                src={getAppIcon(app)} // Change this path to your actual icon path
                alt={app}
                className="app-logo"
              />
              <p
                className="app-name"
                style={{
                  color: hoveredApp === app ? getAppColor(app) : "#333", // Apply dynamic color on hover
                }}
              >
                {app}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="loader-container">
          {/* @ts-ignore */}
          <Lottie options={ChbayahAnimation} height={"300px"} width={"300px"} />
          <p>Aucune application disponible pour vous !</p>
        </div>
      )}
    </div>
  );
}

export default HomeScreen;
