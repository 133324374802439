import React, { useEffect, useState } from "react";
import "./homeheader.scss";
import LogoPizzaieHub from "../../assets/logo/logo-pizzaiehub.png";
import { DisconnectOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, message, Space } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../utils/localstorage";
import isTokenExpired from "../../helpers/isTokenExpired";
import { toast } from "react-toastify";
import Modal from "react-modal";
import getProfileById from "../../services/getProfileById";
import { LocalStorageEnum } from "../../utils/localstorage/LocalStorageEnum";
import { ProfileDataType } from "../../utils/Types/ProfileDataType";
function HomeHeader() {
  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // message.info("Click on left button.");
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if ((e as any)?.key === "1") {
      message.info("Coming soon....");
    }
    if ((e as any)?.key === "2") {
      setShowDisconnectModal(true);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Profile",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "Déconnecter",
      key: "2",
      icon: <DisconnectOutlined />,
      danger: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/home`);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<ProfileDataType | null>();
  const [showDisconnectModal, setShowDisconnectModal] =
    useState<boolean>(false);

  const handleDisconnect = () => {
    deleteFromLocalStorage(LocalStorageEnum.TOKEN);
    deleteFromLocalStorage(LocalStorageEnum.PHONENUMBER);
    navigate(`/login`);
  };

  const handleGetProfileInfo = async () => {
    try {
      setLoading(true);
      const token = getFromLocalStorage(LocalStorageEnum.TOKEN) || "";

      if (isTokenExpired(token) || !token) {
        toast.error("Session expired, you will be disconnected!");
        return <Navigate to="/login" replace />;
      }

      const decodedToken = JSON.parse(atob(token?.split(".")?.[1])) || {};
      const id = decodedToken?.user?.id || decodedToken.id || "";

      if (!id && !id?.length) {
        toast.error("ID not found, try to reconnect or try again later!");

        setLoading(false);
        return;
      }
      const { data } = await getProfileById(id);

      setProfileData(data?.content?.user);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "linear-gradient(to left, #f5e7cc, #febf3f)",
      padding: "5vh",
      borderRadius: "10px",
    },
  };

  useEffect(() => {
    handleGetProfileInfo();
  }, []);
  return (
    <>
      <div className="home-header-container">
        {/* @ts-ignore */}
        <Modal
          isOpen={showDisconnectModal}
          onRequestClose={() => setShowDisconnectModal(false)}
          style={customModalStyles}
          shouldCloseOnEsc
          preventScroll
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#000000",
            }}
          >
            Voulez vous vraiment se déconnecter ?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "50px",
              marginBottom: "20px",
              gap: "20px",
              alignItems: "center",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "700",
              color: "#ffffff",
              borderRadius: "5px",
              padding: "10px 20px",
              border: "none",
              outline: "none",
            }}
          >
            <button
              style={{
                outline: "none",
                background: "white",
                color: "black",
                border: "none",
                padding: "1.5vh 1.5vh",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: "600",
              }}
              onClick={() => setShowDisconnectModal(false)}
            >
              Annuler
            </button>
            <button
              style={{
                outline: "none",
                background: "#febf3f",
                color: "#000000",
                border: "none",
                padding: "1.5vh 1.5vh",
                borderRadius: "5px",
                fontSize: "12px",
                fontWeight: "600",
              }}
              onClick={() => handleDisconnect()}
            >
              Confirmer
            </button>
          </div>
        </Modal>
        <div className="home-header-logo-container" onClick={handleNavigate}>
          <img
            src={LogoPizzaieHub}
            alt="logo login"
            className="home-header-logo-img"
          />
          {/* <p className="home-header-logo-title">Pizzaie HUB</p> */}
        </div>
        <div className="home-header-profile-section">
          <Space wrap>
            <Dropdown.Button
              menu={menuProps}
              placement="bottomLeft"
              icon={<UserOutlined />}
              loading={loading}
              destroyPopupOnHide
              type="text"
              size="middle"
              onClick={handleButtonClick}
            >
              {profileData?.fullname || profileData?.phone || "---"}
            </Dropdown.Button>
          </Space>
        </div>
      </div>
    </>
  );
}

export default HomeHeader;
