// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe-container {
  width: 100%;
  height: 91vh;
  overflow: hidden;
  display: flex;
}
.iframe-container .refresh-button {
  position: absolute;
  margin-top: 2vh;
  margin-left: 2vh;
  outline: none;
  background: rgba(254, 190, 63, 0.3411764706);
  border: none;
  padding: 2vh;
  border-radius: 5px;
}
.iframe-container .loader-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 91vh;
  width: 100%;
  background: #f5f5f5;
  position: absolute;
  z-index: 1000;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/screens/iframe-screen/iframescreen.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;AACF;AAAE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,4CAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;AAEJ;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;AAEJ","sourcesContent":[".iframe-container {\n  width: 100%;\n  height: 91vh;\n  overflow: hidden;\n  display: flex;\n  .refresh-button {\n    position: absolute;\n    margin-top: 2vh;\n    margin-left: 2vh;\n    outline: none;\n    background: #febe3f57;\n    border: none;\n    padding: 2vh;\n    border-radius: 5px;\n  }\n  .loader-content {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 91vh;\n    width: 100%;\n    background: #f5f5f5;\n    position: absolute;\n    z-index: 1000;\n    overflow: hidden;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
