import { AxiosError } from "axios";
import { getRequestLb4 } from "../utils/axios";
import { getFromLocalStorage } from "../utils/localstorage";
import { LocalStorageEnum } from "../utils/localstorage/LocalStorageEnum";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

async function getProfileById(id: string): Promise<DataFromAxiosType> {
  try {
    const token = getFromLocalStorage(LocalStorageEnum.TOKEN) || "";

    const { data } = await getRequestLb4(`/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!data?.success) {
      throw new Error("Error getting profile details : " + data?.message);
    }

    return { data };
  } catch (error: unknown) {
    console.error("Error getting profile details :", error);

    let errorMessage = "Unknown error";
    let errorStatus: number | undefined;

    // Check if the error is an AxiosError
    if (error instanceof AxiosError) {
      errorMessage = error?.response?.data?.message || error?.message;
      errorStatus = error?.response?.status;
    } else if (error instanceof Error) {
      errorMessage = error?.message;
    }

    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: errorMessage,
        status: errorStatus || 500,
        content: [],
      },
    };

    return returnedData;
  }
}

export default getProfileById;
