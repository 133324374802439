import React, { useState } from "react";
import "./confirmationscreen.scss";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";

import "react-phone-number-input/style.css";
import sendOtpSMS from "../../services/sendSmsOtp";
import validateSmsOtp from "../../services/validateSmsOtp";
import verifyAuthenticatorCode from "../../services/verify-authenticator-code";
import { FourDotsLoading } from "../../assets/lottie-animation/animationOptions";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { getFromLocalStorage, setLocalStorage } from "../../utils/localstorage";
import { LocalStorageEnum } from "../../utils/localstorage/LocalStorageEnum";

function ConfirmationScreen() {
  const [loading, setLoading] = useState(false);

  const [smsIsSent, setSmsIsSent] = useState(false);

  const [otpCode, setOtpCode] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [verifyOtpType, setVerifyOtpType] = useState<"AUTHENTICATOR" | "OTP">(
    "AUTHENTICATOR"
  );

  const navigate = useNavigate();
  const location = useLocation();

  const { countryCode, phoneNumber } = location?.state || {};

  async function handleSendOtpSms(): Promise<void> {
    try {
      setLoading(true);

      const phoneNumber =
        getFromLocalStorage(LocalStorageEnum.PHONENUMBER) || "";
      console.log("phone number ", phoneNumber);

      if (!phoneNumber && !phoneNumber?.length) {
        toast.error("Please relogin your phone number not found !");
        setLoading(false);
        navigate("/login");
        return;
      }
      const { data } = await sendOtpSMS({
        body: { countryCode, phoneNumber },
      });

      if (!data?.success) {
        throw new Error("Error sending SMS : " + data?.message);
      }

      setSmsIsSent(true);

      setTimeout(() => {
        setSmsIsSent(false);
      }, 120000);

      toast.success("SMS envoyé avec succés!");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error sending SMS "
      );
    }
  }

  async function handleValidateSmsOtp(): Promise<void> {
    try {
      if (!otpCode || otpCode?.length !== 6) {
        setErrorMessage("OTP not enabled ");
        return;
      }
      setLoading(true);
      const { data } = await validateSmsOtp({
        body: { countryCode, phoneNumber, otp: otpCode },
      });

      if (!data?.success) {
        throw new Error("Error validating  SMS : " + data?.message);
      }
      toast.success("OTP succés !");

      setLocalStorage(LocalStorageEnum.TOKEN, data?.content?.token);

      navigate("/home");

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMessage("");

      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error validating SMS "
      );
    }
  }

  async function handleValidateAuthenticatorCode(): Promise<void> {
    try {
      if (!otpCode || otpCode?.length !== 6) {
        setErrorMessage("OTP not enabled ");
        return;
      }
      setLoading(true);

      const { data } = await verifyAuthenticatorCode(phoneNumber, otpCode);

      if (!data?.success) {
        throw new Error(
          "Error validating  authenticator code : " + data?.message
        );
      }

      toast.success("Authenticator code succés !");

      setLocalStorage(LocalStorageEnum.TOKEN, data?.content?.token);
      navigate("/home");

      setLoading(false);
    } catch (error: any) {
      setErrorMessage("");

      setLoading(false);
      toast.error(
        typeof error?.message === "string"
          ? error?.message
          : "Error validating  authenticator code : "
      );
    }
  }

  return (
    <div className="confim-code-screen-container">
      <div className="background-image-wrapper">
        <div className="form-container">
          <p className="auth-title">ZZ Code</p>
          {loading ? (
            <div className="loading-content">
              {/* @ts-ignore */}
              <Lottie
                options={FourDotsLoading}
                height={"130px"}
                width={"130px"}
              />
            </div>
          ) : (
            <div className="content-container">
              {verifyOtpType === "AUTHENTICATOR" ? (
                <p className="content-description">
                  Veuillez ouvrir votre application Authenticator et entrer le
                  code à 6 chiffres pour confirmer votre identité. Si vous
                  n'avez pas l'application Authenticator, ouvrez l'application
                  PizzaieHub et accédez à l'écran de votre profil pour suivre
                  les étapes nécessaires et obtenir le code d'authentification.
                </p>
              ) : (
                <p className="content-description">
                  Un SMS contenant un code à 6 chiffres a été envoyé à votre
                  numéro de téléphone. Veuillez vérifier vos messages et entrer
                  le code reçu pour confirmer votre identité.
                </p>
              )}
              <div className="code-enter">
                <VerificationInput
                  autoFocus={true}
                  validChars={"0-9"}
                  classNames={{
                    container: "container",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                    characterFilled: "character--filled",
                  }}
                  onChange={(props) => {
                    setOtpCode(props);
                  }}
                />
              </div>
              <div className="input-wrapper">
                <p className="error-message">{errorMessage || ""}</p>
              </div>
              <Tooltip
                id="my-tooltip"
                variant="dark"
                float
                noArrow
                opacity={0.8}
                clickable={false}
              />
              {verifyOtpType === "OTP" && (
                <div className="resend-sms-container">
                  <p>Si vous n'avez pas reçu de code,</p>
                  <p
                    className={
                      verifyOtpType === "OTP" && smsIsSent
                        ? "resned-disabled"
                        : verifyOtpType === "OTP" && !smsIsSent
                        ? "resned-enabled"
                        : ""
                    }
                    onClick={() => {
                      verifyOtpType === "OTP" &&
                        !smsIsSent &&
                        handleSendOtpSms();
                    }}
                    data-tooltip-id={
                      verifyOtpType === "OTP" && smsIsSent ? "my-tooltip" : ""
                    }
                    data-tooltip-content={
                      verifyOtpType === "OTP" && smsIsSent
                        ? "Veuillez patienter 2 minutes avant de renvoyer le code de vérification par SMS."
                        : ""
                    }
                  >
                    {" renvoyez-le"}
                  </p>
                </div>
              )}

              <button
                className="button"
                onClick={() => {
                  verifyOtpType === "OTP"
                    ? handleValidateSmsOtp()
                    : handleValidateAuthenticatorCode();
                }}
              >
                Suivant
              </button>
              <div className="or-text-container">
                <div className="or-text-line" />
                <p className="or-text">OR</p>
                <div className="or-text-line" />
              </div>
              <button
                className="button"
                onClick={() => {
                  setVerifyOtpType((prev) => {
                    verifyOtpType === "AUTHENTICATOR" && handleSendOtpSms();
                    return prev === "OTP" ? "AUTHENTICATOR" : "OTP";
                  });
                }}
              >
                {verifyOtpType === "OTP"
                  ? "Authenticator code"
                  : "Send OTP SMS"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default ConfirmationScreen;
