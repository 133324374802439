import { AxiosError } from "axios";
import { postRequestLb4 } from "../utils/axios";
import { setLocalStorage } from "../utils/localstorage";
import { LocalStorageEnum } from "../utils/localstorage/LocalStorageEnum";

type DataFromAxiosType = {
  data: {
    success: boolean;
    message: string;
    status: number;
    content: Record<string, any>;
  };
};

type BodyType = {
  phoneNumber: string;
};

async function verifyAceesByPhoneNumber(
  phoneNumber: string
): Promise<DataFromAxiosType> {
  try {
    const body: BodyType = {
      phoneNumber,
    };
    const { data } = await postRequestLb4("/verify-user-exist", body);

    if (!data?.success) {
      throw new Error("Error verifyAceesByPhoneNumber : " + data?.message);
    }

    setLocalStorage(LocalStorageEnum.PHONENUMBER, body?.phoneNumber || "");

    return { data };
  } catch (error: unknown) {
    console.error("Error verifyAceesByPhoneNumber :", error);

    let errorMessage = "Unknown error";
    let errorStatus: number | undefined;

    // Check if the error is an AxiosError
    if (error instanceof AxiosError) {
      errorMessage = error?.response?.data?.message || error?.message;
      errorStatus = error?.response?.status;
    } else if (error instanceof Error) {
      errorMessage = error?.message;
    }

    const returnedData: DataFromAxiosType = {
      data: {
        success: false,
        message: errorMessage,
        status: errorStatus || 500,
        content: [],
      },
    };

    return returnedData;
  }
}

export default verifyAceesByPhoneNumber;
